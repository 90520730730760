* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: sans-serif;
  }
  
  header {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    color: #212529;
  }
  
  .nav-area {
    display: flex;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 10px 20px;
  }
  
  .logo {
    text-decoration: none;
    font-size: 25px;
    color: inherit;
    margin-right: 20px;
  }
  
  /* menu on desktop */
  .desktop-nav .menus {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    list-style: none;
  }
  
  .desktop-nav .menu-items {
    position: relative;
    font-size: 14px;
  }
  
  .desktop-nav .menu-items a {
    display: block;
    font-size: inherit;
    color: inherit;
    text-decoration: none;
  }
  
  .desktop-nav .menu-items button {
    display: flex;
    align-items: center;
    color: inherit;
    font-size: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
  }
  
  .desktop-nav button span {
    margin-left: 3px;
  }
  
  .desktop-nav .menu-items > a,
  .desktop-nav .menu-items button {
    text-align: left;
    padding: 0.7rem 1rem;
  }
  
  .desktop-nav .menu-items a:hover,
  .desktop-nav .menu-items button:hover {
    background-color: #f2f2f2;
  }
  
  .desktop-nav .arrow::after {
    content: "";
    display: inline-block;
    margin-left: 0.28em;
    vertical-align: 0.09em;
    border-top: 0.42em solid;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
  }
  
  .desktop-nav .dropdown {
    position: absolute;
    right: 0;
    left: auto;
    box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
      0 4px 6px -2px rgba(71, 63, 79, 0.16);
    font-size: 0.875rem;
    z-index: 9999;
    min-width: 10rem;
    padding: 0.5rem 0;
    list-style: none;
    background-color: #fff;
    border-radius: 0.5rem;
    display: none;
  }
  
  .desktop-nav .dropdown.show {
    display: block;
  }
  
  .desktop-nav .dropdown .dropdown-submenu {
    position: absolute;
    left: 100%;
    top: -7px;
  }
  
  .mobile-nav {
    display: none;
  }
  
  /* menu on mobile */
  @media screen and (max-width: 960px) {
    .nav-area {
      justify-content: space-between;
    }
  
    .desktop-nav {
      display: none;
      text-align: right;
    }
  
    .mobile-nav {
      display: block;
    }
    .mobile-nav .mobile-nav__menu-button {
      color: inherit;
      font-size: inherit;
      border: none;
      background-color: transparent;
      cursor: pointer;
      position: relative;
    }
    .mobile-nav .menus {
      list-style: none;
      position: absolute;
      top: 50px;
      right: 20px;
      box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
        0 4px 6px -2px rgba(71, 63, 79, 0.16);
      z-index: 9999;
      min-width: 12rem;
      padding: 0.5rem 0;
      background-color: #fff;
      border-radius: 0.5rem;
    }
  
    .mobile-nav .menu-items a {
      display: block;
      font-size: inherit;
      color: inherit;
      text-decoration: none;
    }
  
    .mobile-nav .menu-items button {
      display: flex;
      align-items: center;
      color: inherit;
      font-size: inherit;
      border: none;
      background-color: transparent;
      cursor: pointer;
      width: 100%;
    }
  
    .mobile-nav .menu-items > a,
    .mobile-nav .menu-items button {
      text-align: left;
      padding: 0.7rem 1rem;
    }
  
    .mobile-nav .menu-items a:hover,
    .mobile-nav .menu-items button:hover {
      background-color: #f2f2f2;
    }
  
    .mobile-nav .arrow::after {
      content: "";
      display: inline-block;
      margin-left: 1.2em;
      vertical-align: 0.09em;
      border-top: 0.42em solid;
      border-right: 0.32em solid transparent;
      border-left: 0.32em solid transparent;
    }
    .mobile-nav .arrow-close::after {
      content: "";
      display: inline-block;
      margin-left: 1.2em;
      vertical-align: 0.09em;
      border-bottom: 0.42em solid;
      border-right: 0.32em solid transparent;
      border-left: 0.32em solid transparent;
    }
  
    .mobile-nav .dropdown {
      margin-left: 1.2em;
      font-size: 0.9rem;
      padding: 0.5rem 0;
      list-style: none;
      display: none;
    }
  
    .mobile-nav .dropdown.show {
      display: block;
    }
  }
  
  /* page content */
  .content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 3rem 20px;
  }
  
  .content h1 {
    font-size: 2rem;
  }
  
  #error-page {
    /* center content on the page */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    gap: 20px;
  }
  