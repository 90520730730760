.team-select_handler_cont {


    .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
        padding: 15px;
        
    }
    .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
        display: none;
    }

    .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
        border: none;
    }

    .css-igs3ac{
        border: none;
    }

    .css-1636szt {
        display: none;
    }

    .css-qiwgdb.css-qiwgdb.css-qiwgdb {
        padding: 15px;
    }
}