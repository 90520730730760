.custom-style {

    .form-check .form-check-input {
            
            background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23ffffff%27/%3e%3c/svg%3e') !important;
            background-color: #009ef7;
            border: #009ef7 1px solid;
            
    }

    .hover-underline:hover {
        text-decoration: underline;
      }
}


