//top

.cont {
    display: flex;
    flex-direction: column;
    gap: 30px;

    

    .name {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
    background: #009ef7;


        .left {
            width: 50%;

        }

        .right {
            flex: 1;
            display: flex;
            gap: 20px;
            justify-content: end;
        }

    }

    .contact_info-cont {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        overflow: scroll;
        gap: 50px;
         align-items: center;
         padding: 20px;
         padding-bottom: 0px;

         .account {
            display: flex;
            flex-direction: column;

         }
    }

    .tags_teams-combo {
        display: flex;
        flex-direction: column;
        // gap: 50px;

        .tags_cont {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .heading {

            }

            .tags_name {
                display: flex;
                gap: 10px;
                width: 100%;
                // overflow: scroll;
                flex-wrap: wrap;
                
                p {
                    border-radius: 10px;
                    padding: 10px;
                }
            }

        }

        .teams_cont {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 0 20px;

            .heading {

            }

            .teams_name {
                display: flex;
                gap: 10px;
                width: 100%;
                overflow: scroll;
                
                p {
                    border-radius: 10px;
                    padding: 10px;
                }
            }

        }
    }

    .save_button {
        width: calc(100% - 20px);
        display: flex;
        justify-content: end;
        padding: 0 20px;

        button {


        }
    }

    .telephone-field {
        .react-international-phone-country-selector-button {
            padding: 20px 0;
        }
        .react-international-phone-input {
            padding: 20px 0;
        }
    }
}

.react-international-phone-input {
    width: 100%;
}

.custom-style {

    .form-check .form-check-input {
            
            background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23ffffff%27/%3e%3c/svg%3e') !important;
            background-color: #009ef7;
            border: #009ef7 1px solid;
            
    }
}