


.header {
    display: flex;
    flex-direction: column;
    height: 100%;

    .logout {

    }

    .top {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 10px;
        padding-bottom: 0;
        align-items: center;

        .header-left {

            p {
                font-size: 20px;
                font-weight: 500;
                margin: 0;
            }
        }

        .header-center {

            .names-div {
                display: flex;
                gap: 20px;

                .name {
                    white-space: nowrap;
                    padding: 10px 20px;
                    border-radius: 10px;
                    margin: 0;
                    background: #009ef7;
                    color: white;
    
                }
    
                .testing {
                    white-space: nowrap;
                    padding: 10px;
                    border-radius: 10px;
                    margin: 0;
                    background: #009ef7;
                    color: white;
    
                }
            }


        }

        .header-right {
            // flex: 1;
            display: flex;
            justify-content: flex-end;
            gap: 10px;

           
        }
    }

    .support {
        background: #009ef7 ;
        width: 100%;
        display: flex;
        justify-content: end;
        align-items: center;
        padding: 5px;


        p {

            border: 1px solid black;
            padding: 7px 25px;
            margin: 0;
        border-radius: 10px;
        font-size: 15px;

        }
    }
}

.charts-header {
    margin-top: 15px;

    .charts-combo {
        display: flex,;
        justify-content: space-between;
        padding: 10px;
        width: 100%;
        align-items: center;

        .charts-left {
            display: flex;
            gap: 10px;
            height: 50px;
            width: 70%;
            align-items: center;

            .date {
                min-width: 200px;
                white-space: nowrap;
            }

        }

        .charts-right {
            flex: 1;
            
             p {
                display: flex;
                gap: 10px;
                align-items: center;
                justify-content: end;
                margin: 0;

                .create-ticket {
                    border-radius: 6px;
                    background: #009ef7;
                    color: white;
                    padding: 8px;
                    cursor: pointer;
                    transition: background 0.3s ease, transform 0.3s ease;

                    &:hover {
                        background: #009ef7; // Darker shade on hover
                        transform: scale(1.05); // Slight zoom effect
                    }


                }
             }

        }

    }
}

.client-filter-cont {
    // margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    background: white;
    // padding: 10px 20px;

    .css-1x5jdmq {
        padding: 8px !important;

    }

    .MuiTablePagination-displayedRows {
        margin: 0;
    }
    
     .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input  {
        padding: 8px !important;

    }
    
    .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
        padding: 8px !important;
    }

    .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input  {
        padding: 8px !important;
    }
    
    .css-levciy-MuiTablePagination-displayedRows {
        display: flex;
        width: 90%;
    }
    
    .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
        // padding-right: 0px !important;
        padding-left: 10px !important;
    }
    
    .pagination-button {
        transition: background-color 0.3s ease;
    
        background: red;
    
    /* Hover effect for non-disabled buttons */
    .pagination-button:not([disabled]):hover {
        background-color: red;
        color: white;
    }
    }
    
    .css-qiwgdb.MuiSelect-select  {
        padding: 8px !important;
    }

  

    .combo {
        display: flex;
        gap: 60px;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;

      

        .bar-chart {
        width: 100%;
        height: 40%;

        .apexcharts-toolbar {
            display: none;
        }


        }

    }

}

.client-filter-cont {

}

// Table

.client-table {

    .table-headerr {
        display: flex;
        justify-content: space-between;
        border: 1px solid grey;
        align-items: center;
        padding: 10px;
        border-radius: 10px;
        margin-bottom: 20px;

       
    }

        .filter-result {
            width: 70%;

        }

        .search {
            flex: 1;

            span {
                display: flex;
                gap: 15px;
                align-items: center;

                input {
                    padding: 10px;
                    border-radius: 10px;
                    border: none;
                    width: 100%;
                    border: 1px solid grey;
                }
            }

        }
    }

.apexcharts-datalabels-group {
    display: none;
}

.MuiDataGrid-virtualScrollerContent {
    
}

.table-headerr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;

  

}

.textContainer {
    width: 250px; text-overflow: ellipsis;
     overflow: hidden; white-space: nowrap

}


