input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}





@keyframes blink {
  50% {
    opacity: 0;
  }
}
// Loader overlay styles
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background-color: rgba(0, 0, 0, 0.1); // Black with opacity
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; // Ensure it appears above all other elements

  // Spinner styles
  .spinner {
    width: 50px;
    height: 50px;
    // border: 5px solid rgba(255, 255, 255, 0.3); // Light white border
    border-top: 5px solid white; // Solid white top
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
}

// Spin animation
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.disconnect_cont {

  .bi-telephone::before {
    transform: rotate(136deg);
  }
}