.telephony-container {
    position: relative;
    top: -40px;
    background: white;
    margin-bottom: 7px;
    padding: 5px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  

    
    // .telephony-toggle-btn {
    // //   padding: 4px;
    // //   background-color: #007bff;
    //   color: white;
    //   border: none;
    //   border-radius: 4px;
    //   cursor: pointer;
    //   transition: all 0.2s ease-in-out;
  
    //   &:hover {
    //     background-color: grey;
    //   }
    // }
  
    .telephony-wrapper {
      transition: all 0.4s ease-in-out;
      opacity: 1;
      max-height: 500px; // Adjust to your component's height
      overflow: hidden;
      width: 100%;
  
      &.telephony-hide {
        opacity: 0;
        max-height: 0;
      }
    }
}
  