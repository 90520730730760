//top

.cont {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .name {
        display: flex;
        justify-content: space-between;
        // width: calc(100% - 20px);
        width: 100%;
        align-items: center;
    // background: #009ef7;
    padding: 30px;


        .left {
            width: 50%;
            position: relative;

        }

        .right {
            flex: 1;
            display: flex,;
            gap: 10px;
            justify-content: end;
        }

    }

}



.add-contact-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 5%;
    position: relative;

    .input-wrapper {
        position: relative;
    

    .form-control {
        position: relative;
        z-index: 2;
    }


.suggestions-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    border: 1px solid #ccc;
    border-top: none;
    position: absolute;
    top: calc(100% + 10px); /* Position the suggestions list directly below the input field with a gap */
    left: 0;
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
    z-index: 10; /* Ensure suggestions list is above the button */
    background-color: #fff;
.suggestion-item {
    padding: 10px;
    cursor: pointer;
}


.suggestion-item:hover {
    background-color: #f0f0f0;
}
}
    }

button {
    z-index: 1; 
}

}