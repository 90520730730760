/* ChatPanel.css */
.chat-panel {
    position: fixed;
    bottom: 0;
    width: 300px;
    /* border: 1px solid #ccc; */
    box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 8px 0px;
    background-color: #fff;
    border-radius: 10px 10px 0 0;
    font-family: Arial, sans-serif;
    transition: all 0.3s ease;
    /* Smooth transition when new panels open */
}

.chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border-radius: 10px 10px 0 0;
}

.chat-controls button {
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    margin-left: 5px;
    font-size: 16px;
}

.chat-body {
    padding: 10px;
    height: 600px;
    overflow-y: auto;
}

.minimized .chat-body {
    display: none;
}

.minimized {
    height: auto;
}

.dots-cont {
    position: relative;
    right: 0px;
    bottom: 0px;
}

.dot {
    width: 4px;
    height: 4px;
    background: var(--color-text-bot);
    display: inline-block;
    border-radius: 50%;
    right: 0px;
    bottom: 0px;
    margin: 0px 2.5px;
    position: relative;
    animation: jump 1s infinite;
}

.dots-cont:hover>.dot {
    animation: none;
}

.dots-cont .dot-1 {
    -webkit-animation-delay: 100ms;
    animation-delay: 100ms;
}

.dots-cont .dot-2 {
    -webkit-animation-delay: 200ms;
    animation-delay: 200ms;
}

.dots-cont .dot-3 {
    -webkit-animation-delay: 300ms;
    animation-delay: 300ms;
}

@keyframes jump {
    0% {
        bottom: 0px;
    }

    20% {
        bottom: 5px;
    }

    40% {
        bottom: 0px;
    }
}

.custom-dropdown-toggle {
    cursor: pointer;
    padding: 0.375rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent !important;
    /* Remove background */
    box-shadow: none !important;
    /* Remove box-shadow */
}

.custom-dropdown-toggle:after {
    content: none !important;
    /* Remove the arrow */
}

.custom-dropdown-toggle:hover {
    background: none;
    /* Remove hover background */
}

.custom-dropdown-toggle>i {
    color: #6c757d;
    /* Change the icon color if needed */
}