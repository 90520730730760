
// .agent_mapping_cont {

    
    .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
        background: white;
    }

    .css-1ald77x {
        background: white;
    }

// }


.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
    background-color: rgba(0, 0, 0, 0.1);
}

.addExtension_cont {

     display: "flex";
     flex-direction: "column"; 
     gap: "20px";



     .dropdown-container {
        position: relative;
      
        label {
          margin-bottom: 0.5rem;
          display: inline-block;
        }
      
        .dropdown-select {
          max-height: 200px; // Adjust height as needed
          overflow-y: auto; // Scrollable dropdown if items exceed max-height
          position: relative;
          border: 1px solid #ccc;
          border-radius: 4px;
          padding: 0.5rem;
      
          &:focus {
            outline: none;
            box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
          }
        }
      
        select:focus {
          outline: none;
          border-color: #007bff; // Highlight border color on focus
          box-shadow: 0 0 3px #007bff;
        }
      }
      

}