// .css-bhp9pd-MuiPaper-root-MuiCard-root  {
//     box-shadow: 00;
// }

.ticket-access-control-cont {
    .css-1ald77x {
        background: white !important;

    }
    .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
        background: white !important;
    }
}